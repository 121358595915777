<template>
  <div class="container mt-3">
    <h1 class="text-center">Анкетирование студента</h1>
    <div class="card mt-3 mb-3">
      <h5 class="card-header">Информация о студенте</h5>
      <div class="card-body">
        <p class="card-text"><strong>ФИО:</strong> {{ surveyStudentInfo.last_name }} {{ surveyStudentInfo.first_name }}</p>
        <p class="card-text"><strong>Баркод:</strong> {{ surveyStudentInfo.barcode }}</p>
        <p class="card-text"><strong>Образовательная программа:</strong>{{ surveyStudentInfo.education_speciality_name }} - {{ surveyStudentInfo.education_speciality_code }}</p>
      </div>
    </div>
    <div class="table-block">
      <table class="table overflow-scroll">
        <thead>
        <tr>
          <th scope="col">№</th>
          <th scope="col">Вопрос</th>
          <th scope="col">Никогда</th>
          <th scope="col">Редко</th>
          <th scope="col">Иногда</th>
          <th scope="col">Часто</th>
          <th scope="col">Всегда</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(question, index) in surveyQuestions" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ question.question }}</td>
          <td v-for="(score, idx) in surveyScore" :key="idx">
            <input @input="chooseScore(question.id, $event)" type="radio"
                   :checked="buttonActive[index] ? buttonActive[index].survey_score_id == score.id : false"
                   :value="score.id" :name="index">
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <button class="btn btn-outline-primary" @click="sendScore" :disabled="buttonDisabled">Отправить</button>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "Survey",
  data() {
    return {
      buttonActive: [],
      buttonDisabled: false,
      student_id: this.$route.query.student_id,
      semester_id: this.$route.query.semester
    }
  },
  methods: {
    ...mapActions('survey', ['GET_SURVEY_QUESTIONS', 'GET_SURVEY_SCORE', 'POST_SURVEY_SCORE', 'GET_SURVEY_SEND_DATA', 'GET_SHORT_STUDENT_INFO']),
    chooseScore(question_id, e) {
      let indexArray = this.buttonActive.findIndex(i => i.id === question_id)
      if (indexArray >= 0) {
        this.buttonActive.splice(indexArray, 1)
      }
      this.buttonActive.push({
        survey_question_id: question_id,
        survey_score_id: e.target.value,
        student_id: this.student_id,
        semester: this.semester_id
      })
      console.log(this.buttonActive)
    },
    sendScore() {
      if (this.surveyQuestions.length !== this.buttonActive.length) {
        this.$error({title: "Заполните все данные!"})
      } else {
        if (this.POST_SURVEY_SCORE(this.buttonActive)) {
          this.buttonDisabled = true
          this.$message({title: "Действие выполнено!"})
        }
        console.log(this.buttonActive)
      }
    }
  },
  computed: {
    ...mapState('survey', ['surveyQuestions', 'surveyScore', 'surveyStudentInfo'])
  },
  async mounted() {
    await this.GET_SHORT_STUDENT_INFO(this.student_id);
    await this.GET_SURVEY_QUESTIONS();
    await this.GET_SURVEY_SCORE();
    this.buttonActive = await this.GET_SURVEY_SEND_DATA({student_id: this.student_id, semester: this.semester_id});
    if (this.buttonActive.length > 0) {
      this.buttonDisabled = true;
    }
  }
}
</script>

<style scoped>
.table-block{
  overflow: auto;
}
</style>